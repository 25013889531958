@charset "UTF-8";
/**
 * Color Schemas
 */
/**
 * Font styles
 */
/**
 *
 */
/**
 * Breakpoints
 */
/**
 * Dimensions
 */
/**
 * Color Schemas
 */
/**
 * Font styles
 */
/**
 *
 */
/**
 * Breakpoints
 */
/**
 * Dimensions
 */
/**
 * Color Schemas
 */
/**
 * Font styles
 */
/**
 *
 */
/**
 * Breakpoints
 */
/**
 * Dimensions
 */
/**
 * Color Schemas
 */
/**
 * Font styles
 */
/**
 *
 */
/**
 * Breakpoints
 */
/**
 * Dimensions
 */
/**
 * 文書など、コンテンツの横幅を決めたい時に最大幅を指定する。
 */
.limited-content-box, .article-paper {
  max-width: 940px; }

/**
 * メディアの横幅を決めたい時の最大幅
 */
.media-content-box {
  max-width: 800px; }
  .media-content-box img, .media-content-box video {
    width: 100%; }

/**
 * サイドバーとコンテンツを横並び２カラムにする
 */
.hpanel {
  *zoom: 1; }
  .hpanel:after {
    content: "";
    display: table;
    clear: both; }

.hpanel-sidebar {
  float: left;
  width: 200px;
  margin: 0 !important; }
  .hpanel-sidebar > :first-child {
    margin-top: 0; }

.hpanel-content {
  float: right;
  width: 100%;
  padding-left: 210px;
  margin: 0 0 0 -210px !important; }
  .hpanel-content > :first-child {
    margin-top: 0; }

/**
 * Color Schemas
 */
/**
 * Font styles
 */
/**
 *
 */
/**
 * Breakpoints
 */
/**
 * Dimensions
 */
/**
 * Color Schemas
 */
/**
 * Font styles
 */
/**
 *
 */
/**
 * Breakpoints
 */
/**
 * Dimensions
 */
.content-wrapper {
  position: relative;
  padding: 0 20px;
  margin: 0 auto;
  min-width: 920px; }

.page-title {
  text-shadow: 0 0 2px #ffffff;
  font-size: 1.75em;
  font-weight: bold;
  margin: 1em 0; }
  .page-title::before {
    transform: scale(2, 2);
    margin: 0 1em 0 .5em; }
  .page-title .label {
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    display: inline-block;
    margin: 0;
    position: relative;
    top: -2px;
    color: #FFF;
    background: #444;
    padding: .25em .5em;
    font-size: 0.8em;
    font-weight: normal; }
    .page-title .label.light {
      background: #777; }
  .page-title .inline-meta {
    text-shadow: none;
    display: inline-block;
    font-size: 14px;
    font-weight: normal;
    position: relative;
    top: -2px; }
    .page-title .inline-meta .meta-title {
      margin: 0 0 2px;
      font-size: 10px;
      font-weight: bold;
      color: #cccccc; }
    .page-title .inline-meta .label {
      text-shadow: none;
      border-radius: 2px;
      background: #cccccc;
      font-weight: normal;
      font-size: 9px; }

#page-header {
  *zoom: 1;
  position: fixed;
  margin: 0;
  top: 0;
  overflow: visible;
  width: 100%;
  min-width: 940px;
  height: 36px;
  z-index: 10;
  background: #b8ddc9 url("images/header.png") repeat-x top left;
  background-size: 2000px 36px; }
  #page-header:after {
    content: "";
    display: table;
    clear: both; }
  #page-header ul {
    list-style: none;
    margin: 0;
    padding: 0; }
  #page-header li {
    text-shadow: 0 0 2px #ffffff;
    margin: 0;
    padding: 0;
    display: inline-block;
    text-align: center;
    color: #444; }
  #page-header li.current {
    background: rgba(35, 156, 116, 0.8);
    color: #fff; }
    #page-header li.current a {
      text-shadow: 0 0 2px rgba(0, 0, 0, 0.25); }
  #page-header a, #page-header a:hover {
    color: inherit; }
  #page-header a {
    display: block;
    height: 36px;
    line-height: 36px; }

#page-content {
  margin-top: 36px;
  padding-top: 1.5em;
  padding-bottom: 16em; }

#page-footer {
  position: fixed;
  bottom: 0;
  color: #FFF;
  text-align: left;
  background: #1a7255;
  padding: .5em 1em;
  margin: 0;
  width: 100%;
  min-width: 940px;
  box-sizing: border-box;
  border-bottom: 5px solid #239c74;
  z-index: 10; }

#site-identity {
  box-shadow: 0 0 2px #ffffff;
  position: absolute;
  left: 20px;
  top: 0;
  width: 200px;
  height: 38px;
  margin: 0;
  padding: 0; }
  #site-identity a {
    display: block;
    overflow: hidden;
    line-height: 2;
    color: #fff;
    font-size: 16.8px;
    width: 200px;
    height: 38px;
    line-height: 38px; }
    #site-identity a::before {
      content: url("images/m_site_identity.svg"); }

#site-navigation {
  position: absolute;
  left: 240px;
  top: 0; }
  #site-navigation li {
    position: relative;
    height: 44px;
    padding: 0 1em; }
    @media (min-width: 1280px) {
      #site-navigation li {
        padding: 0 2em; } }
  #site-navigation li.current {
    box-shadow: 0 0 2px #ffffff; }
    #site-navigation li.current::before {
      content: url("images/c_trigona_down.svg");
      width: 8px;
      height: 6px;
      position: absolute;
      margin-left: -4px;
      left: 50%;
      bottom: 16px; }

#user-menu {
  position: absolute;
  right: 20px;
  top: 0; }
  #user-menu a {
    text-decoration: none; }
    #user-menu a[href]:hover {
      text-decoration: underline; }
  #user-menu li {
    margin-left: 1em; }
    #user-menu li a {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
  #user-menu li.username {
    max-width: 120px; }
    @media (min-width: 1280px) {
      #user-menu li.username {
        max-width: 180px; } }
    #user-menu li.username a {
      cursor: default; }

/**
 * Color Schemas
 */
/**
 * Font styles
 */
/**
 *
 */
/**
 * Breakpoints
 */
/**
 * Dimensions
 */
/**
 * Color Schemas
 */
/**
 * Font styles
 */
/**
 *
 */
/**
 * Breakpoints
 */
/**
 * Dimensions
 */
body {
  padding: 0;
  color: #444;
  font-size: 14px;
  font-family: 'メイリオ',Meiryo,'ヒラギノ角ゴ Pro W3','Hiragino Kaku Gothic Pro','ＭＳ Ｐゴシック',sans-serif;
  background: #f6f9f4;
  line-height: 1.6; }

td, th, button, input, select, option, textarea {
  color: #444;
  font-size: 14px;
  line-height: 1.6; }

a, a:hover {
  color: #239c74; }

/**
 * Color Schemas
 */
/**
 * Font styles
 */
/**
 *
 */
/**
 * Breakpoints
 */
/**
 * Dimensions
 */
/**
 * Color Schemas
 */
/**
 * Font styles
 */
/**
 *
 */
/**
 * Breakpoints
 */
/**
 * Dimensions
 */
form .field-message {
  margin: 0.25em 0 0; }

form > *:first-child {
  margin-top: 0; }

form h1, form h2, form h3, form h4, form h5, form h6 {
  margin-top: 1em;
  margin-bottom: 1em; }

.disabled,
button.btn[disabled] {
  opacity: .25; }

input[type="checkbox"][disabled] {
  opacity: .65; }

label {
  font-weight: normal; }

fieldset {
  position: relative;
  margin-bottom: 1em;
  padding: 1em;
  border-radius: 4px;
  border: #cccccc 1px solid; }
  fieldset.with-legend {
    padding-top: 1.5em; }

legend {
  display: inline-block;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  padding: 0 .5em;
  width: auto;
  height: 1.5em;
  line-height: 1.6em;
  border: none;
  background: #cccccc;
  position: absolute;
  left: 1em;
  top: -0.75em; }

.inline-control-group {
  *zoom: 1; }
  .inline-control-group:after {
    content: "";
    display: table;
    clear: both; }
  .inline-control-group .form-control, .inline-control-group button, .inline-control-group select, .inline-control-group .btn-group, .inline-control-group .btn-toolbar, .inline-control-group .control-group {
    display: inline-block;
    position: relative;
    margin-top: 0;
    margin-bottom: 0; }

.control-group-title {
  border-radius: 4px;
  position: absolute;
  z-index: -10;
  left: 0;
  top: -16px;
  color: #FFF;
  font-size: 11px;
  background: #cccccc;
  padding: .1em .5em 1em; }
  .control-group-title.as-theme {
    background: #239c74; }

/**
 * Color Schemas
 */
/**
 * Font styles
 */
/**
 *
 */
/**
 * Breakpoints
 */
/**
 * Dimensions
 */
/**
 * Color Schemas
 */
/**
 * Font styles
 */
/**
 *
 */
/**
 * Breakpoints
 */
/**
 * Dimensions
 */
table {
  margin: 0;
  background: #fff; }
  table .section td, table .section th {
    color: #FFF;
    background-color: #239c74 !important; }
  table .functions {
    background: #eee;
    text-align: left !important; }
  table tfoot .functions {
    margin-top: 1em;
    margin-bottom: 1em; }

/**
 * STANDARD FORM STYLE
 */
.tbl-standard {
  margin: 1em 0;
  border-collapse: collapse;
  border: 1px solid #cccccc;
  border-left: hidden;
  border-right: hidden;
  width: 100%; }
  .tbl-standard thead, .tbl-standard tfoot {
    text-align: center; }
  .tbl-standard td, .tbl-standard th {
    border: 1px solid #cccccc;
    padding: .5em 1em;
    font-weight: normal; }
  .tbl-standard tr.section td, .tbl-standard th {
    background: #eee; }
  .tbl-standard th label {
    font-weight: inherit; }
  .tbl-standard label {
    margin: 0; }
  .tbl-standard .alert {
    margin: 2em auto !important; }
  .tbl-standard .pagination {
    margin: 0; }
  .tbl-standard .help-block {
    margin: .125em 0; }
  .tbl-standard .form-control {
    display: inline-block; }
  .tbl-standard .form-control-static {
    display: inline-block;
    padding-bottom: 7px; }

/**
 * Color Schemas
 */
/**
 * Font styles
 */
/**
 *
 */
/**
 * Breakpoints
 */
/**
 * Dimensions
 */
/**
 * Color Schemas
 */
/**
 * Font styles
 */
/**
 *
 */
/**
 * Breakpoints
 */
/**
 * Dimensions
 */
.article-paper {
  background: #fff;
  padding: 2em;
  margin-top: 2em;
  margin-bottom: 2em;
  border: 1px solid #cccccc; }
  .article-paper > *:first-child {
    margin-top: 0; }

h1 {
  font-size: 2.5em;
  margin: 2em 0 1em; }

h2 {
  font-size: 1.6em;
  font-weight: bold;
  margin: 2em 0 1em; }

h3 {
  font-size: 1.2em;
  font-weight: bold;
  margin: 2em 0 .5em; }

h4 {
  font-size: 1em;
  font-weight: bold;
  margin: 2em 0 .5em; }

h5 {
  font-size: 1em;
  font-weight: bold;
  margin: 2em 0 .5em; }

h6 {
  font-size: 1em;
  font-weight: bold;
  margin: 2em 0 .5em; }

.bordered {
  border-radius: 2px;
  background: #cccccc;
  padding: .25em .5em; }

.bordered-info {
  background: #b8ddc9; }

p {
  line-height: 1.6;
  margin: 0 0 1em; }

/**
 * Color Schemas
 */
/**
 * Font styles
 */
/**
 *
 */
/**
 * Breakpoints
 */
/**
 * Dimensions
 */
/**
 * Color Schemas
 */
/**
 * Font styles
 */
/**
 *
 */
/**
 * Breakpoints
 */
/**
 * Dimensions
 */
.block-center {
  margin-left: auto !important;
  margin-right: auto !important; }

.block-center-in-parent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.inline {
  display: inline-block !important;
  position: relative; }

.inline-block {
  display: inline-block !important;
  position: relative; }

.text-left {
  text-align: left !important; }

.text-center {
  text-align: center !important; }

.text-right {
  text-align: right !important; }

.text-white {
  color: #FFF !important; }

.text-weak {
  font-size: 0.9em;
  color: #777; }

.text-normal {
  font-weight: normal; }

.text-light {
  color: #777; }

.text-stamp {
  color: #d63366 !important; }

.text-sm {
  font-size: 11px; }

.text-xs {
  font-size: 8px !important; }

.text-lg {
  font-size: 16px; }

.text-xl {
  font-size: 18px; }

.w-auto {
  width: auto !important; }

.w-xshort, .w-xs {
  width: 80px !important; }

.w-short, .w-sm {
  width: 120px !important; }

.w-middle, .w-md {
  width: 180px !important; }

.w-long, .w-lg {
  width: 240px !important; }

.w-xlong, .w-xl {
  width: 360px !important; }

.w-25pc {
  width: 25% !important;
  max-width: none !important; }

.w-50pc {
  width: 50% !important;
  max-width: none !important; }

.w-75pc {
  width: 75% !important;
  max-width: none !important; }

.w-100pc, .w-full {
  width: 100% !important;
  max-width: none !important; }

.mgn-none {
  margin: 0 !important; }

.mgn-hor-none {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.mgn-ver-none {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.mgn-top-none {
  margin-top: 0 !important; }

.mgn-rgt-none {
  margin-right: 0 !important; }

.mgn-btm-none {
  margin-bottom: 0 !important; }

.mgn-lft-none {
  margin-left: 0 !important; }

.pdg-none {
  padding: 0 !important; }

.pdg-hor-none {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.pdg-ver-none {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.pdg-top-none {
  padding-top: 0 !important; }

.pdg-rgt-none {
  padding-right: 0 !important; }

.pdg-btm-none {
  padding-bottom: 0 !important; }

.pdg-lft-none {
  padding-left: 0 !important; }

.mgn-xs {
  margin: 0.25em !important; }

.mgn-hor-xs {
  margin-left: 0.25em !important;
  margin-right: 0.25em !important; }

.mgn-ver-xs {
  margin-top: 0.25em !important;
  margin-bottom: 0.25em !important; }

.mgn-top-xs {
  margin-top: 0.25em !important; }

.mgn-rgt-xs {
  margin-right: 0.25em !important; }

.mgn-btm-xs {
  margin-bottom: 0.25em !important; }

.mgn-lft-xs {
  margin-left: 0.25em !important; }

.pdg-xs {
  padding: 0.25em !important; }

.pdg-hor-xs {
  padding-left: 0.25em !important;
  padding-right: 0.25em !important; }

.pdg-ver-xs {
  padding-top: 0.25em !important;
  padding-bottom: 0.25em !important; }

.pdg-top-xs {
  padding-top: 0.25em !important; }

.pdg-rgt-xs {
  padding-right: 0.25em !important; }

.pdg-btm-xs {
  padding-bottom: 0.25em !important; }

.pdg-lft-xs {
  padding-left: 0.25em !important; }

.mgn-sm {
  margin: 1em !important; }

.mgn-hor-sm {
  margin-left: 1em !important;
  margin-right: 1em !important; }

.mgn-ver-sm {
  margin-top: 1em !important;
  margin-bottom: 1em !important; }

.mgn-top-sm {
  margin-top: 1em !important; }

.mgn-rgt-sm {
  margin-right: 1em !important; }

.mgn-btm-sm {
  margin-bottom: 1em !important; }

.mgn-lft-sm {
  margin-left: 1em !important; }

.pdg-sm {
  padding: 1em !important; }

.pdg-hor-sm {
  padding-left: 1em !important;
  padding-right: 1em !important; }

.pdg-ver-sm {
  padding-top: 1em !important;
  padding-bottom: 1em !important; }

.pdg-top-sm {
  padding-top: 1em !important; }

.pdg-rgt-sm {
  padding-right: 1em !important; }

.pdg-btm-sm {
  padding-bottom: 1em !important; }

.pdg-lft-sm {
  padding-left: 1em !important; }

.mgn-md {
  margin: 2em !important; }

.mgn-hor-md {
  margin-left: 2em !important;
  margin-right: 2em !important; }

.mgn-ver-md {
  margin-top: 2em !important;
  margin-bottom: 2em !important; }

.mgn-top-md {
  margin-top: 2em !important; }

.mgn-rgt-md {
  margin-right: 2em !important; }

.mgn-btm-md {
  margin-bottom: 2em !important; }

.mgn-lft-md {
  margin-left: 2em !important; }

.pdg-md {
  padding: 2em !important; }

.pdg-hor-md {
  padding-left: 2em !important;
  padding-right: 2em !important; }

.pdg-ver-md {
  padding-top: 2em !important;
  padding-bottom: 2em !important; }

.pdg-top-md {
  padding-top: 2em !important; }

.pdg-rgt-md {
  padding-right: 2em !important; }

.pdg-btm-md {
  padding-bottom: 2em !important; }

.pdg-lft-md {
  padding-left: 2em !important; }

.mgn-lg {
  margin: 4em !important; }

.mgn-hor-lg {
  margin-left: 4em !important;
  margin-right: 4em !important; }

.mgn-ver-lg {
  margin-top: 4em !important;
  margin-bottom: 4em !important; }

.mgn-top-lg {
  margin-top: 4em !important; }

.mgn-rgt-lg {
  margin-right: 4em !important; }

.mgn-btm-lg {
  margin-bottom: 4em !important; }

.mgn-lft-lg {
  margin-left: 4em !important; }

.pdg-lg {
  padding: 4em !important; }

.pdg-hor-lg {
  padding-left: 4em !important;
  padding-right: 4em !important; }

.pdg-ver-lg {
  padding-top: 4em !important;
  padding-bottom: 4em !important; }

.pdg-top-lg {
  padding-top: 4em !important; }

.pdg-rgt-lg {
  padding-right: 4em !important; }

.pdg-btm-lg {
  padding-bottom: 4em !important; }

.pdg-lft-lg {
  padding-left: 4em !important; }

.mgn-025em {
  margin: 0.25em !important; }

.mgn-hor-025em {
  margin-left: 0.25em !important;
  margin-right: 0.25em !important; }

.mgn-ver-025em {
  margin-top: 0.25em !important;
  margin-bottom: 0.25em !important; }

.mgn-top-025em {
  margin-top: 0.25em !important; }

.mgn-rgt-025em {
  margin-right: 0.25em !important; }

.mgn-btm-025em {
  margin-bottom: 0.25em !important; }

.mgn-lft-025em {
  margin-left: 0.25em !important; }

.pdg-025em {
  padding: 0.25em !important; }

.pdg-hor-025em {
  padding-left: 0.25em !important;
  padding-right: 0.25em !important; }

.pdg-ver-025em {
  padding-top: 0.25em !important;
  padding-bottom: 0.25em !important; }

.pdg-top-025em {
  padding-top: 0.25em !important; }

.pdg-rgt-025em {
  padding-right: 0.25em !important; }

.pdg-btm-025em {
  padding-bottom: 0.25em !important; }

.pdg-lft-025em {
  padding-left: 0.25em !important; }

.mgn-en {
  margin: 0.5em !important; }

.mgn-hor-en {
  margin-left: 0.5em !important;
  margin-right: 0.5em !important; }

.mgn-ver-en {
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important; }

.mgn-top-en {
  margin-top: 0.5em !important; }

.mgn-rgt-en {
  margin-right: 0.5em !important; }

.mgn-btm-en {
  margin-bottom: 0.5em !important; }

.mgn-lft-en {
  margin-left: 0.5em !important; }

.pdg-en {
  padding: 0.5em !important; }

.pdg-hor-en {
  padding-left: 0.5em !important;
  padding-right: 0.5em !important; }

.pdg-ver-en {
  padding-top: 0.5em !important;
  padding-bottom: 0.5em !important; }

.pdg-top-en {
  padding-top: 0.5em !important; }

.pdg-rgt-en {
  padding-right: 0.5em !important; }

.pdg-btm-en {
  padding-bottom: 0.5em !important; }

.pdg-lft-en {
  padding-left: 0.5em !important; }

.mgn-em {
  margin: 1em !important; }

.mgn-hor-em {
  margin-left: 1em !important;
  margin-right: 1em !important; }

.mgn-ver-em {
  margin-top: 1em !important;
  margin-bottom: 1em !important; }

.mgn-top-em {
  margin-top: 1em !important; }

.mgn-rgt-em {
  margin-right: 1em !important; }

.mgn-btm-em {
  margin-bottom: 1em !important; }

.mgn-lft-em {
  margin-left: 1em !important; }

.pdg-em {
  padding: 1em !important; }

.pdg-hor-em {
  padding-left: 1em !important;
  padding-right: 1em !important; }

.pdg-ver-em {
  padding-top: 1em !important;
  padding-bottom: 1em !important; }

.pdg-top-em {
  padding-top: 1em !important; }

.pdg-rgt-em {
  padding-right: 1em !important; }

.pdg-btm-em {
  padding-bottom: 1em !important; }

.pdg-lft-em {
  padding-left: 1em !important; }

.mgn-2em {
  margin: 2em !important; }

.mgn-hor-2em {
  margin-left: 2em !important;
  margin-right: 2em !important; }

.mgn-ver-2em {
  margin-top: 2em !important;
  margin-bottom: 2em !important; }

.mgn-top-2em {
  margin-top: 2em !important; }

.mgn-rgt-2em {
  margin-right: 2em !important; }

.mgn-btm-2em {
  margin-bottom: 2em !important; }

.mgn-lft-2em {
  margin-left: 2em !important; }

.pdg-2em {
  padding: 2em !important; }

.pdg-hor-2em {
  padding-left: 2em !important;
  padding-right: 2em !important; }

.pdg-ver-2em {
  padding-top: 2em !important;
  padding-bottom: 2em !important; }

.pdg-top-2em {
  padding-top: 2em !important; }

.pdg-rgt-2em {
  padding-right: 2em !important; }

.pdg-btm-2em {
  padding-bottom: 2em !important; }

.pdg-lft-2em {
  padding-left: 2em !important; }

.mgn-4em {
  margin: 4em !important; }

.mgn-hor-4em {
  margin-left: 4em !important;
  margin-right: 4em !important; }

.mgn-ver-4em {
  margin-top: 4em !important;
  margin-bottom: 4em !important; }

.mgn-top-4em {
  margin-top: 4em !important; }

.mgn-rgt-4em {
  margin-right: 4em !important; }

.mgn-btm-4em {
  margin-bottom: 4em !important; }

.mgn-lft-4em {
  margin-left: 4em !important; }

.pdg-4em {
  padding: 4em !important; }

.pdg-hor-4em {
  padding-left: 4em !important;
  padding-right: 4em !important; }

.pdg-ver-4em {
  padding-top: 4em !important;
  padding-bottom: 4em !important; }

.pdg-top-4em {
  padding-top: 4em !important; }

.pdg-rgt-4em {
  padding-right: 4em !important; }

.pdg-btm-4em {
  padding-bottom: 4em !important; }

.pdg-lft-4em {
  padding-left: 4em !important; }

.scale-2x::before {
  transform: scale(2, 2) !important; }

.scale-3x::before {
  transform: scale(3, 3) !important; }

@font-face {
  font-family: 'LigatureSymbols';
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/LigatureSymbols-2.11.woff") format("woff"), url("../fonts/LigatureSymbols-2.11.ttf") format("truetype"), url("../fonts/LigatureSymbols-2.11.svg#LigatureSymbols") format("svg"); }

.lsf::before, .page-title::before, #user-menu li a::before, .alert.with-icon::before, .dialog.error .dialog-header h1::before, .dialog.warning .dialog-header h1::before, .dialog.confirm .dialog-header h1::before, .grid-table .memo-button::before, .grid-table.waku2 .icon-bookgrade::before,
.grid-table.waku2 .icon-favorite::before, .rireki-mark.mark-homework::before, .rireki-mark.mark-printer::before, .step-selector.with-next-arrow li.active .btn::after, .entry-card.grade-high::before, .entry-card.grade-low::before, .homework-list .subject::before, .step-selector li.has-homework .btn::before, .step-selector li.completed .btn::before {
  transform: scale(1.5, 1.5);
  vertical-align: baseline;
  display: inline-block;
  margin-left: .25em;
  margin-right: .25em;
  font-weight: normal;
  font-family: 'LigatureSymbols';
  text-rendering: optimizeLegibility;
  font-smoothing: antialiased;
  -webkit-font-feature-settings: "liga" 1, "dlig" 1;
  -moz-font-feature-settings: "liga=1, dlig=1";
  -ms-font-feature-settings: "liga" 1, "dlig" 1;
  -o-font-feature-settings: "liga" 1, "dlig" 1;
  font-feature-settings: "liga" 1, "dlig" 1; }

.lsf-lg::before, .rireki-mark.mark-homework::before, .rireki-mark.mark-printer::before {
  transform: scale(2, 2); }

.lsf-xl::before {
  transform: scale(3, 3) !important; }

.lsf-user::before, .lsf-you::before, .lsf-human::before, .lsf-man::before {
  content: ""; }

.lsf-friend::before, .page-title.as-friend::before, .lsf-users::before {
  content: ""; }

.lsf-group::before {
  content: ""; }

.lsf-good::before, .lsf-like::before {
  content: ""; }

.lsf-bad::before, .lsf-nogood::before {
  content: ""; }

.lsf-etc::before, .lsf-ellipsis::before {
  content: ""; }

.lsf-folder::before {
  content: ""; }

.lsf-bell::before {
  content: ""; }

.lsf-ban::before, .page-title.as-ban::before {
  content: ""; }

.lsf-remove::before, .lsf-close::before {
  content: ""; }

.lsf-pen::before, .page-title.as-entry::before, .lsf-pencil::before, .lsf-write::before {
  content: ""; }

.lsf-edit::before, .lsf-addnew::before {
  content: ""; }

.lsf-delete::before, .lsf-trash::before {
  content: ""; }

.lsf-print::before, .rireki-mark.mark-printer::before {
  content: ""; }

.lsf-barcode::before {
  content: ""; }

.lsf-save::before, .lsf-stock::before, .page-title.as-stock::before {
  content: ""; }

.lsf-phone::before, .lsf-call::before {
  content: ""; }

.lsf-image::before, .lsf-img::before {
  content: ""; }

.lsf-login::before, .page-title.as-login::before, .lsf-in::before {
  content: ""; }

.lsf-logout::before, #user-menu li.logout a::before, .lsf-out::before {
  content: ""; }

.lsf-dailycalendar::before {
  content: ""; }

.lsf-search::before {
  content: ""; }

.lsf-check::before, .lsf-ok::before {
  content: ""; }

.lsf-checkbox::before {
  content: ""; }

.lsf-checkboxempty::before {
  content: ""; }

.lsf-plus::before, .lsf-add::before {
  content: ""; }

.lsf-minus::before, .lsf-add::before {
  content: ""; }

.lsf-view::before, .lsf-eye::before {
  content: ""; }

.lsf-point::before,
.lsf-flag::before {
  content: ""; }

.lsf-koumoku::before, .page-title.as-koumoku::before,
.lsf-pin::before {
  content: ""; }

.lsf-kyouka::before,
.lsf-book::before,
.homework-list .subject::before {
  content: ""; }

.lsf-file::before,
.lsf-test::before,
.page-title.as-test::before {
  content: ""; }

.lsf-homework::before, .rireki-mark.mark-homework::before, .step-selector li.has-homework .btn::before,
.lsf-bookmark::before {
  content: ""; }

.lsf-building::before, .page-title.as-building::before, .page-title.as-kyousitu::before,
.lsf-kyousitu::before {
  content: ""; }

.lsf-memo::before, .grid-table .memo-button::before {
  content: ""; }

.lsf-album::before {
  content: ""; }

.lsf-video::before {
  content: ""; }

.lsf-playmedia::before {
  content: ""; }

.lsf-camera::before {
  content: ""; }

.lsf-upload::before {
  content: ""; }

.lsf-crown::before, .step-selector li.completed .btn::before {
  content: ""; }

.lsf-college::before {
  content: ""; }

.lsf-coffee::before, .lsf-cup::before {
  content: ""; }

.lsf-spa::before, .lsf-onsen::before {
  content: ""; }

.lsf-comment::before, .dialog.warning .dialog-header h1::before, .lsf-balloon::before {
  content: ""; }

.lsf-comments::before, .lsf-balloons::before {
  content: ""; }

.lsf-info::before, .dialog.confirm .dialog-header h1::before, .lsf-information::before {
  content: ""; }

.lsf-help::before {
  content: ""; }

.lsf-star::before, .grid-table.waku2 .icon-bookgrade::before {
  content: ""; }

.lsf-starempty::before {
  content: ""; }

.lsf-heart::before, .lsf-love::before {
  content: ""; }

.lsf-heartempty::before, .grid-table.waku2 .icon-favorite::before {
  content: ""; }

.lsf-twinkle::before, .lsf-spark::before, .lsf-hot::before {
  content: ""; }

.lsf-tile::before, .page-title::before, .page-title.as-overview::before {
  content: ""; }

.lsf-tilemenu::before, .page-title.as-detailed-history::before {
  content: ""; }

.lsf-list::before, .page-title.as-list::before {
  content: ""; }

.lsf-notify::before, .dialog.warning .dialog-header h1::before {
  content: ""; }

.lsf-arrowup::before {
  content: ""; }

.lsf-arrowright::before, .step-selector.with-next-arrow li.active .btn::after {
  content: ""; }

.lsf-arrowdown::before, .step-selector.with-next-arrow.align-vertical li.active .btn::after {
  content: ""; }

.lsf-arrowleft::before {
  content: ""; }

.lsf-back::before {
  content: ""; }

.lsf-next::before {
  content: ""; }

.lsf-dropdown::before {
  content: ""; }

.lsf-sort::before {
  content: ""; }

.lsf-frustrate::before {
  content: ""; }

.lsf-laugh::before {
  content: ""; }

.lsf-wink::before {
  content: ""; }

.lsf-smile::before, #user-menu li.username a::before, .entry-card.grade-high::before {
  content: ""; }

.lsf-surprise::before {
  content: ""; }

.lsf-trouble::before, .dialog.error .dialog-header h1::before, .entry-card.grade-low::before {
  content: ""; }

.lsf-setting::before, .lsf-gear::before {
  content: ""; }

.lsf-setup::before, .page-title.as-settings::before, .lsf-wrench::before, .lsf-spanner::before {
  content: ""; }

@font-face {
  font-family: 'cj-font';
  src: url("../fonts/cj-font.ttf?60eeyz") format("truetype"), url("../fonts/cj-font.woff?60eeyz") format("woff"), url("../fonts/cj-font.svg?60eeyz#cj-font") format("svg");
  font-weight: normal;
  font-style: normal; }

.cj-font {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'cj-font' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  .cj-font:before {
    display: inline-block;
    vertical-align: baseline; }

.cj-ic-rect-question:before {
  content: "\e918"; }

.cj-ic-rect-answer:before {
  content: "\e919"; }

.cj-ic-rect-explain:before {
  content: "\e91a"; }

.cj-ic-arrow-down:before {
  content: "\e910"; }

.cj-ic-arrow-left:before {
  content: "\e911"; }

.cj-ic-arrow-right:before {
  content: "\e912"; }

.cj-ic-arrow-up:before {
  content: "\e913"; }

.cj-ic-forward:before {
  content: "\e914"; }

.cj-ic-rewind:before {
  content: "\e915"; }

.cj-ic-to-end:before {
  content: "\e916"; }

.cj-ic-to-first:before {
  content: "\e917"; }

.cj-ic-play:before {
  content: "\e90d"; }

.cj-ic-pause:before {
  content: "\e90e"; }

.cj-ic-stop:before {
  content: "\e90f"; }

.cj-ic-youten:before {
  content: "\e900"; }

.cj-ic-multimedia:before {
  content: "\e901"; }

.cj-ic-headphone:before {
  content: "\e902"; }

.cj-ic-challenge:before {
  content: "\e903"; }

.cj-ic-repeat:before {
  content: "\e904"; }

.cj-ic-repeat-1:before {
  content: "\e905"; }

.cj-ic-repeat-2:before {
  content: "\e906"; }

.cj-ic-repeat-3:before {
  content: "\e907"; }

.cj-ic-interval-0:before {
  content: "\e908"; }

.cj-ic-interval-1:before {
  content: "\e909"; }

.cj-ic-interval-2:before {
  content: "\e90a"; }

.cj-ic-accent-on:before {
  content: "\e90b"; }

.cj-ic-accent-off:before {
  content: "\e90c"; }

/**
 * Color Schemas
 */
/**
 * Font styles
 */
/**
 *
 */
/**
 * Breakpoints
 */
/**
 * Dimensions
 */
/**
 * Color Schemas
 */
/**
 * Font styles
 */
/**
 *
 */
/**
 * Breakpoints
 */
/**
 * Dimensions
 */
.cj-ctrl-slider {
  position: relative;
  display: inline-block;
  background-color: #232323;
  border: 1px solid #777;
  border-radius: 5px;
  height: 10px;
  width: 100%;
  vertical-align: middle; }
  .cj-ctrl-slider .bar-0 {
    position: absolute;
    height: 8px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 4px; }
  .cj-ctrl-slider .handle {
    position: absolute;
    width: 20px;
    height: 20px;
    transform: translateY(-25%);
    border-radius: 10px;
    background: #f9f9f9;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.25); }
  .cj-ctrl-slider[disabled] {
    opacity: .5; }

/**
 * Color Schemas
 */
/**
 * Font styles
 */
/**
 *
 */
/**
 * Breakpoints
 */
/**
 * Dimensions
 */
/**
 * Color Schemas
 */
/**
 * Font styles
 */
/**
 *
 */
/**
 * Breakpoints
 */
/**
 * Dimensions
 */
/**
 * Color Schemas
 */
/**
 * Font styles
 */
/**
 *
 */
/**
 * Breakpoints
 */
/**
 * Dimensions
 */
/**
 * Color Schemas
 */
/**
 * Font styles
 */
/**
 *
 */
/**
 * Breakpoints
 */
/**
 * Dimensions
 */
.media-ctrlbar {
  *zoom: 1;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  position: relative;
  background: rgba(35, 35, 35, 0.8);
  border-radius: 4px;
  font-size: 12.6px;
  color: #fff;
  margin: .5em; }
  .media-ctrlbar:after {
    content: "";
    display: table;
    clear: both; }
  .media-ctrlbar > *,
  .media-ctrlbar .ctrl-group > * {
    display: inline-block;
    height: 32px;
    min-width: 54px;
    vertical-align: middle;
    border: hidden;
    background: none;
    position: relative;
    color: #fff;
    font-size: 12.6px;
    border-radius: 0;
    text-align: center; }
  .media-ctrlbar .ctrl-group {
    white-space: nowrap; }
  .media-ctrlbar .media-ctrl-button,
  .media-ctrlbar .media-ctrl-label {
    padding: 0 .5em; }
    .media-ctrlbar .media-ctrl-button > .inner,
    .media-ctrlbar .media-ctrl-label > .inner {
      display: inline-block;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      width: 100%; }
  .media-ctrlbar .media-ctrl-label[disabled] {
    opacity: 0.5; }
  .media-ctrlbar .media-ctrl-button:not(:disabled):hover {
    background: rgba(255, 255, 255, 0.125);
    color: #fff; }
  .media-ctrlbar .media-ctrl-button:disabled {
    opacity: 0.5; }
  .media-ctrlbar .media-ctrl-seekbar {
    padding: 5px 1em; }
  .media-ctrlbar .icon::before {
    font-size: 1.4em;
    vertical-align: middle !important;
    transform: translateY(-0.05em); }

/**
 * Color Schemas
 */
/**
 * Font styles
 */
/**
 *
 */
/**
 * Breakpoints
 */
/**
 * Dimensions
 */
/**
 * Color Schemas
 */
/**
 * Font styles
 */
/**
 *
 */
/**
 * Breakpoints
 */
/**
 * Dimensions
 */
.callout {
  box-shadow: 0 0 0 5px #fff;
  margin: 2em auto;
  padding: 1.5em;
  border: 1px solid #cccccc;
  border-left-width: 5px;
  background: #fff; }
  .callout h3 {
    margin-top: 0;
    margin-bottom: 5px; }
  .callout *:last-child {
    margin-bottom: 0 !important; }

.callout-danger {
  background-color: #f2dede;
  border-color: #e4b9c0; }
  .callout-danger h3 {
    color: #b94a48; }

.callout-warning {
  background-color: #fcf8e3;
  border-color: #f2cf87; }
  .callout-warning h3 {
    color: #8a6d3b; }

.callout-info {
  background-color: #d9edf7;
  border-color: #9ad0ea; }
  .callout-info h3 {
    color: #34789a; }

.alert {
  margin: 2em 0;
  padding: 1em;
  position: relative;
  max-width: 40em;
  min-width: 25em; }
  .alert.alert-mini {
    margin: 1em auto;
    padding: 0.25em 0.5em;
    display: inline-block; }
  .alert.with-icon {
    padding-left: 5em;
    min-height: 5em; }
    .alert.with-icon::before {
      transform: scale(4.5, 4.5);
      position: absolute;
      top: 1.75em;
      left: 2em; }
    .alert.with-icon.lsf-comment::before, .dialog.warning .dialog-header h1.alert.with-icon::before, .alert.with-icon.lsf-notify::before, .dialog.warning .dialog-header h1.alert.with-icon::before, .alert.with-icon.lsf-bell::before {
      transform: scale(4, 4); }
  .alert > *:first-child {
    margin-top: 0; }
  .alert > *:last-child {
    margin-bottom: 0; }
  .alert.centered {
    margin-left: auto;
    margin-right: auto; }

.alert-success {
  color: #468847;
  border-color: #aed48e;
  background-color: #dff0d8; }

.alert-warning {
  color: #c09853;
  border-color: #f2cf87;
  background-color: #fcf8e3; }

.alert-danger {
  color: #b94a48;
  border-color: #e4b9c0;
  background-color: #f2dede; }

.alert-info {
  color: #3a87ad;
  border-color: #9ad0ea;
  background-color: #d9edf7; }

/**
 * Color Schemas
 */
/**
 * Font styles
 */
/**
 *
 */
/**
 * Breakpoints
 */
/**
 * Dimensions
 */
/**
 * Color Schemas
 */
/**
 * Font styles
 */
/**
 *
 */
/**
 * Breakpoints
 */
/**
 * Dimensions
 */
.dialog {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999; }
  .dialog .alert {
    margin: 1em auto; }
  .dialog .alert:first-child {
    margin-top: 0; }
  .dialog .dialog-header h1 .lsf::before, .dialog .dialog-header h1 .page-title::before, .dialog .dialog-header h1 #user-menu li a::before, #user-menu li .dialog .dialog-header h1 a::before, .dialog .dialog-header h1 .alert.with-icon::before, .dialog.error .dialog-header h1 h1::before, .dialog.warning .dialog-header h1 h1::before, .dialog.confirm .dialog-header h1 h1::before, .dialog .dialog-header h1 .grid-table .memo-button::before, .grid-table .dialog .dialog-header h1 .memo-button::before, .dialog .dialog-header h1 .grid-table.waku2 .icon-bookgrade::before, .grid-table.waku2 .dialog .dialog-header h1 .icon-bookgrade::before, .dialog .dialog-header h1
  .grid-table.waku2 .icon-favorite::before,
  .grid-table.waku2 .dialog .dialog-header h1 .icon-favorite::before, .dialog .dialog-header h1 .rireki-mark.mark-homework::before, .dialog .dialog-header h1 .rireki-mark.mark-printer::before, .dialog .dialog-header h1 .step-selector.with-next-arrow li.active .btn::after, .step-selector.with-next-arrow li.active .dialog .dialog-header h1 .btn::after, .dialog .dialog-header h1 .entry-card.grade-high::before, .dialog .dialog-header h1 .entry-card.grade-low::before, .dialog .dialog-header h1 .homework-list .subject::before, .homework-list .dialog .dialog-header h1 .subject::before, .dialog .dialog-header h1 .step-selector li.has-homework .btn::before, .step-selector li.has-homework .dialog .dialog-header h1 .btn::before, .dialog .dialog-header h1 .step-selector li.completed .btn::before, .step-selector li.completed .dialog .dialog-header h1 .btn::before {
    transform: scale(2, 2);
    font-weight: normal;
    margin-right: .5em; }
  .dialog.error .dialog-window,
  .dialog.error .dialog-body,
  .dialog.error .dialog-footer {
    color: #b94a48;
    background: #f2dede;
    border-color: #e4b9c0; }
  .dialog.error .dialog-header {
    color: #FFF;
    background: #b94a48;
    border-color: #e4b9c0; }
    .dialog.error .dialog-header h1::before {
      transform: scale(2, 2);
      margin-right: 1em; }
  .dialog.warning .dialog-window,
  .dialog.warning .dialog-body,
  .dialog.warning .dialog-footer {
    color: #c09853;
    background: #fcf8e3;
    border-color: #f2cf87; }
  .dialog.warning .dialog-header {
    color: #FFF;
    background: #c09853;
    border-color: #f2cf87; }
    .dialog.warning .dialog-header h1::before {
      transform: scale(2, 2);
      margin-right: 1em; }
  .dialog.confirm .dialog-header h1::before {
    transform: scale(2, 2);
    margin-right: 1em; }
  .dialog.warning .dialog-window,
  .dialog.warning .dialog-body,
  .dialog.warning .dialog-footer {
    color: #c09853;
    background: #fcf8e3;
    border-color: #f2cf87; }
  .dialog.warning .dialog-header {
    color: #FFF;
    background: #c09853;
    border-color: #f2cf87; }
    .dialog.warning .dialog-header h1::before {
      transform: scale(2, 2);
      margin-right: 1em; }

.dialog-backdrop,
.flash-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5); }

.dialog-window {
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 701;
  margin: auto;
  background: #f6f9f4;
  min-width: 420px;
  min-height: 80px;
  max-width: 90%;
  max-height: 90%;
  overflow: hidden;
  box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.5); }
  .dialog-window .now-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

.dialog-header {
  padding: 1em;
  background: #239c74;
  border-bottom: 1px solid #1a7255;
  width: 100%; }
  .dialog-header h1 {
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
    margin: 0;
    color: #FFF;
    font-size: 1.25em;
    font-weight: bold; }

.dialog-body {
  *zoom: 1;
  background: #fff;
  padding: 1em;
  width: 100%;
  overflow: auto;
  min-height: 6em; }
  .dialog-body:after {
    content: "";
    display: table;
    clear: both; }

.dialog-footer {
  *zoom: 1;
  border-top: 1px solid #cccccc;
  background-color: #f6f9f4;
  padding: 1em;
  text-align: center;
  width: 100%; }
  .dialog-footer:after {
    content: "";
    display: table;
    clear: both; }

.dialog-footer-left {
  float: left; }

.dialog-footer-right {
  float: right; }

/**
 * Color Schemas
 */
/**
 * Font styles
 */
/**
 *
 */
/**
 * Breakpoints
 */
/**
 * Dimensions
 */
/**
 * Color Schemas
 */
/**
 * Font styles
 */
/**
 *
 */
/**
 * Breakpoints
 */
/**
 * Dimensions
 */
.flash .flash-window {
  transform: translate(-50%, -50%);
  box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  position: fixed;
  top: 45%;
  left: 50%;
  z-index: 701;
  margin: auto;
  background: #f6f9f4;
  min-width: 420px;
  max-width: 90%;
  max-height: 90%;
  overflow: hidden;
  padding: 1em 2em;
  border: 4px solid;
  text-align: center; }
  .flash .flash-window > *:first-child {
    margin-top: 0; }
  .flash .flash-window > *:last-child {
    margin-bottom: 0; }

.flash.info .flash-window {
  color: #3a87ad;
  background: #d9edf7;
  border-color: #9ad0ea; }

.flash.warning .flash-window {
  color: #c09853;
  background: #fcf8e3;
  border-color: #f2cf87; }

.flash.danger .flash-window {
  color: #b94a48;
  background: #f2dede;
  border-color: #e4b9c0; }

.flash.success .flash-window {
  color: #468847;
  background: #dff0d8;
  border-color: #aed48e; }

/**
 * Color Schemas
 */
/**
 * Font styles
 */
/**
 *
 */
/**
 * Breakpoints
 */
/**
 * Dimensions
 */
/**
 * Color Schemas
 */
/**
 * Font styles
 */
/**
 *
 */
/**
 * Breakpoints
 */
/**
 * Dimensions
 */
.box {
  border-radius: 4px;
  position: relative;
  margin: 2em auto;
  padding: 40px 20px 20px;
  border: 5px solid #cccccc;
  background: #f9f9f9; }
  .box .box-title {
    border-radius: 4px;
    position: absolute;
    top: -5px;
    left: -5px;
    background: #cccccc;
    padding: .25em 1em; }
  .box *:first-child {
    margin-top: 0; }
  .box *:last-child {
    margin-bottom: 0; }

.box-shadowed {
  box-shadow: 2px 2px 2px red; }

.box-theme {
  border-color: #239c74; }
  .box-theme .box-title {
    background-color: #239c74;
    color: #FFF; }

/**
 * Color Schemas
 */
/**
 * Font styles
 */
/**
 *
 */
/**
 * Breakpoints
 */
/**
 * Dimensions
 */
/**
 * Color Schemas
 */
/**
 * Font styles
 */
/**
 *
 */
/**
 * Breakpoints
 */
/**
 * Dimensions
 */
/**
 * パネル風ボタン。
 * メインページなどで利用。
 */
.panel-buttons {
  list-style-type: none;
  margin: 2em 0;
  padding: 0; }
  .panel-buttons li {
    display: inline-block;
    margin: 0 1em 0 0;
    padding: 0;
    vertical-align: top; }
  .panel-buttons a {
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    color: #FFF;
    background: #b8ddc9;
    display: block;
    padding: 10px;
    width: 160px;
    height: 100px; }
  .panel-buttons a:hover {
    background: #239c74; }

/**
 * フローティングパレット
 */
.nav-floating {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  text-shadow: 0 0 0 5px rgba(0, 0, 0, 0.18);
  border-radius: 4px;
  position: fixed;
  z-index: 10;
  top: 197px;
  right: -30px; }
  .nav-floating .control-group-title {
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.25); }
  .nav-floating .btn {
    position: relative;
    height: 54px;
    padding-right: 40px;
    overflow: hidden;
    min-width: 8em; }
    .nav-floating .btn.current {
      background: #fdfefd; }
  .nav-floating.as-theme {
    box-shadow: 0, 0, 0, 5px, rgba(35, 156, 116, 0.5);
    border-color: rgba(35, 156, 116, 0.5); }
    .nav-floating.as-theme .control-group-title {
      background: #239c74; }
    .nav-floating.as-theme .btn {
      border-color: rgba(35, 156, 116, 0.5);
      border-left: hidden;
      border-right: hidden; }
      .nav-floating.as-theme .btn:first-child {
        border-top: hidden; }
      .nav-floating.as-theme .btn:last-child {
        border-bottom: hidden; }
      .nav-floating.as-theme .btn.current {
        background: #fdfefd; }

/**
 * フローティングパレット（日付入力用）
 */
.nav-floating.for-test-date-input {
  top: 330px;
  right: 10px; }
  .nav-floating.for-test-date-input .btn {
    height: 32px;
    padding-left: 8px;
    padding-right: 8px; }
  .nav-floating.for-test-date-input input {
    height: 32px; }

/**
 * btn コントロールを用いた、タブスタイルの表現
 */
.tab-btn-groups {
  *zoom: 1;
  margin: 2em -20px;
  padding: 0 20px;
  overflow: hidden;
  position: relative; }
  .tab-btn-groups:after {
    content: "";
    display: table;
    clear: both; }
  .tab-btn-groups::before {
    display: inline-block;
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 1px;
    width: 100%;
    background: #cccccc;
    z-index: 1; }
  .tab-btn-groups .btn-group {
    position: relative;
    z-index: 2;
    margin-bottom: 0; }
  .tab-btn-groups .as-tab-style .btn {
    position: relative;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .tab-btn-groups .as-tab-style .btn.btn-default.active {
    border-bottom-color: #f6f9f4;
    background: #f6f9f4 !important; }

/**
 * ナビゲーションバー
 */
.navbar {
  overflow: hidden;
  border-radius: 4px;
  color: #777;
  border: 1px solid #cccccc;
  background: #f9f9f9;
  padding: 0 1em; }
  .navbar > * {
    float: left;
    list-style: none;
    margin: 0; }
  .navbar a {
    display: block;
    color: #777;
    padding: 1em; }
  .navbar a:hover {
    background: #cccccc; }
  .navbar .header {
    text-shadow: 0 0 2px #ffffff;
    transform: scale(1.4, 1.4);
    padding: 1em 3em 1em 1.5em; }
  .navbar .items {
    margin: 0;
    padding: 0; }
  .navbar li {
    display: table-cell;
    margin: 0;
    padding: 1em;
    vertical-align: middle; }
    .navbar li a {
      margin: -1em; }
    .navbar li:first-child {
      padding-left: 0; }
    .navbar li:last-child {
      padding-right: 0; }
    .navbar li a:hover {
      opacity: .5;
      background: #f9f9f9; }
    .navbar li.active a {
      background: #e0e0e0; }
  .navbar .btn {
    margin-top: -1em;
    margin-bottom: -1em; }

/**
 * Bootstrap pager にスタイルを追加
 */
.pager {
  margin: 1em 2em;
  display: inline-block; }
  .pager .active a {
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
    color: #FFF;
    background: #239c74;
    border-color: #1a7255; }

.btn-group {
  padding-left: 20px; }
  .btn-group .btn {
    border: 1px solid #ddd;
    width: 100px;
    padding: 0; }
    .btn-group .btn a {
      display: block;
      padding: 6px 12px; }
  .btn-group .active {
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
    background: #239c74;
    border-color: #1a7255; }
    .btn-group .active a {
      color: #FFF; }

/**
 * Color Schemas
 */
/**
 * Font styles
 */
/**
 *
 */
/**
 * Breakpoints
 */
/**
 * Dimensions
 */
/**
 * Color Schemas
 */
/**
 * Font styles
 */
/**
 *
 */
/**
 * Breakpoints
 */
/**
 * Dimensions
 */
.navtab {
  overflow: hidden;
  position: relative;
  display: table;
  list-style: none;
  margin: 2em 0 1.5em;
  padding: 0;
  background: #fff;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  /**
	 * 縦型スタイル
	 */
  /**
	 * タブナビゲーション（濃）
	 */
  /**
	 * 番号付き
	 */ }
  .navtab li {
    display: table-cell;
    margin: 0;
    padding: 0;
    width: 1%;
    border-bottom: 5px solid #cccccc; }
  .navtab a {
    display: block;
    position: relative;
    margin: 0;
    padding: 1em;
    color: #444 !important; }
  .navtab a:hover {
    text-decoration: none; }
  .navtab a[href]:hover {
    text-decoration: none;
    opacity: .5; }
  .navtab li.active {
    border-bottom-color: #239c74 !important; }
  .navtab .label {
    text-shadow: none;
    font-weight: normal;
    line-height: 1;
    padding: .2em .4em;
    color: #FFF;
    background: #444; }
  .navtab.as-vertical {
    display: block; }
    .navtab.as-vertical li {
      width: 100%;
      display: block;
      border-bottom: 1px solid #cccccc; }
      .navtab.as-vertical li a {
        border-right: 5px solid #cccccc; }
      .navtab.as-vertical li:first-child {
        border-top: hidden; }
    .navtab.as-vertical li.active {
      border-bottom-color: #cccccc !important; }
      .navtab.as-vertical li.active a {
        border-right-color: #239c74; }
  .navtab.as-dark {
    border: hidden; }
    .navtab.as-dark li {
      border-color: #777;
      background: #eee; }
    .navtab.as-dark a {
      color: #FFF;
      background: transparent; }
    .navtab.as-dark a:hover {
      color: #444; }
      .navtab.as-dark a:hover .label {
        background: #444;
        color: #FFF; }
    .navtab.as-dark li.active {
      background: #239c74;
      border-color: #1a7255 !important; }
      .navtab.as-dark li.active a {
        cursor: default;
        color: #FFF !important; }
      .navtab.as-dark li.active a:hover {
        color: #FFF !important; }
      .navtab.as-dark li.active .label {
        color: #239c74 !important;
        background: #fff !important; }
  .navtab.with-number {
    counter-reset: pointnum; }
    .navtab.with-number a {
      position: relative;
      text-shadow: none;
      padding-left: 50px; }
    .navtab.with-number a::before {
      counter-increment: pointnum;
      content: counter(pointnum);
      font-size: 1.25em;
      position: absolute;
      width: 40px;
      left: 0;
      top: 0;
      bottom: 0;
      line-height: 50px;
      text-align: center;
      color: #444; }
    .navtab.with-number a::before {
      background-color: #eee; }
    .navtab.with-number li.active a::before {
      background-color: #239c74;
      color: #FFF; }
    .navtab.with-number.as-dark a::before {
      background-color: rgba(119, 119, 119, 0.5);
      color: #fff; }
    .navtab.with-number.as-dark li.active a::before {
      background-color: #1a7255;
      color: #FFF; }

/**
 * Color Schemas
 */
/**
 * Font styles
 */
/**
 *
 */
/**
 * Breakpoints
 */
/**
 * Dimensions
 */
/**
 * Color Schemas
 */
/**
 * Font styles
 */
/**
 *
 */
/**
 * Breakpoints
 */
/**
 * Dimensions
 */
.fixtbl-wrapper {
  border-width: 1px solid #cccccc; }

.grid-table {
  border-collapse: separate;
  border-spacing: 0;
  margin: 1em 0;
  border-top: 1px solid #cccccc;
  border-left: 1px solid #cccccc;
  line-height: 0;
  empty-cells: show;
  max-width: none; }
  .grid-table button {
    padding: 0;
    margin: 0;
    border: none;
    background: none; }
  .grid-table .cell-corner-label {
    position: absolute;
    top: 0;
    left: 0;
    padding: 1px 2px 0;
    background: rgba(255, 255, 255, 0.75);
    font-size: 10px;
    color: #777;
    max-width: 95%;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .grid-table .cell-inner {
    position: relative;
    width: 100%;
    height: 100%;
    color: inherit;
    border: none;
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
    text-align: inherit;
    line-height: 1.1; }
  .grid-table .cell-button {
    display: block;
    background-image: url(images/bg-grad.png);
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
    cursor: pointer; }
    .grid-table .cell-button:hover {
      background-image: none;
      text-decoration: none; }
  .grid-table .cell-input {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    vertical-align: middle; }
  .grid-table .cell-input-error {
    position: absolute;
    bottom: 0;
    padding: .25em 0;
    width: 100%;
    white-space: nowrap;
    background-color: #d9534f;
    color: #FFF;
    font-size: .8em;
    display: none; }
  .grid-table .row-highlight td {
    background-color: #fbffd0; }
  .grid-table .has-error .cell-input-error {
    display: block; }
  .grid-table p {
    margin: 0;
    padding: 0;
    line-height: 1.1; }
  .grid-table .high-height td {
    height: 75px; }
  .grid-table .section td,
  .grid-table .section-head1 td,
  .grid-table .section-head2 td {
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
    color: #FFF;
    line-height: 1.2em;
    padding: 3px; }
  .grid-table .section .cell-inner,
  .grid-table .section-head1 .cell-inner,
  .grid-table .section-head2 .cell-inner {
    background: none; }
  .grid-table .section td {
    height: 24px;
    padding: 6px; }
  .grid-table .section-head1 {
    height: 50px; }
    .grid-table .section-head1 td {
      height: 50px;
      background: #239c74;
      padding-top: 15px;
      padding-left: 10px;
      text-align: left;
      font-size: 1.5em;
      line-height: 1; }
  .grid-table .section-head2 {
    height: 25px; }
    .grid-table .section-head2 td {
      text-shadow: 0 0 2px #ffffff;
      height: 25px;
      padding-top: 5px;
      padding-left: 10px;
      background: #b8ddc9;
      text-align: left;
      font-size: 1.1em;
      line-height: 1;
      font-weight: bold;
      color: #444; }
  .grid-table td {
    background: #f9f9f9;
    padding: 0;
    position: relative;
    width: 60px;
    height: 60px;
    line-height: 0;
    border-color: #cccccc;
    border-style: solid;
    border-width: 0 1px 1px 0;
    text-align: center;
    vertical-align: middle;
    font-size: 1em;
    font-weight: normal; }
  .grid-table .check-test-cell.old-log div .cell-body,
  .grid-table .check-test-cell.old-log div .cell-content, .grid-table.waku2 .check-test-cell.old-log div .book-meta, .grid-table.waku2 .check-test-cell.old-log div .book-image, .grid-table.waku2 .check-test-cell.old-log div .book-memo {
    opacity: 0.4; }
    .grid-table .check-test-cell.old-log div .cell-body.text-left,
    .grid-table .check-test-cell.old-log div .cell-content.text-left, .grid-table.waku2 .check-test-cell.old-log div .text-left.book-meta, .grid-table.waku2 .check-test-cell.old-log div .text-left.book-image, .grid-table.waku2 .check-test-cell.old-log div .text-left.book-memo {
      opacity: 1.0 !important; }
  .grid-table .toutatu-test-cell.old-log div .cell-head {
    opacity: 1.0; }
  .grid-table .toutatu-test-cell.old-log div .cell-body {
    opacity: 0.4; }
  .grid-table .rireki-mark.old-log {
    opacity: 0.4; }
  .grid-table .old-log img {
    opacity: 0.4; }
  .grid-table .grade-high {
    background-color: #b5dafd !important; }
    .grid-table .grade-high.old-log {
      background-color: rgba(181, 218, 253, 0.4) !important;
      color: rgba(68, 68, 68, 0.4); }
  .grid-table .grade-middle {
    background-color: #f8ffac !important; }
    .grid-table .grade-middle.old-log {
      background-color: rgba(248, 255, 172, 0.4) !important;
      color: rgba(68, 68, 68, 0.4); }
  .grid-table .grade-low {
    background-color: #ffcccc !important; }
    .grid-table .grade-low.old-log {
      background-color: rgba(255, 204, 204, 0.4) !important;
      color: rgba(68, 68, 68, 0.4); }
  .grid-table .finished {
    background-color: #f8ffac !important; }
    .grid-table .finished.old-log {
      background-color: rgba(248, 255, 172, 0.4) !important;
      color: rgba(68, 68, 68, 0.4); }
  .grid-table .finished-light {
    background-color: #fbffd0 !important; }
    .grid-table .finished-light.old-log {
      background-color: rgba(251, 255, 208, 0.4) !important;
      color: rgba(68, 68, 68, 0.4); }
  .grid-table .fixtable-fixed-top-boundary {
    border-bottom-width: 1px; }
  .grid-table .fixtable-fixed-right-boundary {
    border-left-width: 1px; }
  .grid-table .fixtable-fixed-bottom-boundary {
    border-top-width: 1px; }
  .grid-table .fixtable-fixed-left-boundary {
    border-right-width: 1px; }
  .grid-table .fixtable-content-top-boundary {
    border-top-width: 0; }
  .grid-table .fixtable-content-right-boundary {
    border-right-width: 0; }
  .grid-table .fixtable-content-bottom-boundary {
    border-bottom-width: 0; }
  .grid-table .fixtable-content-left-boundary {
    border-left-width: 0; }
  .grid-table .w2 {
    width: 120px !important; }
  .grid-table .w3 {
    width: 180px !important; }
  .grid-table .w4 {
    width: 240px !important; }
  .grid-table .w5 {
    width: 300px !important; }
  .grid-table .w6 {
    width: 360px !important; }
  .grid-table .w7 {
    width: 420px !important; }
  .grid-table .cell-head {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 17px;
    color: #444;
    border-bottom: 1px dotted #cccccc;
    background: rgba(255, 255, 255, 0.75);
    font-size: 11px;
    text-align: center;
    padding: 2px 0; }
    .grid-table .cell-head.as-black {
      color: #fff;
      border-bottom: 1px solid rgba(0, 0, 0, 0.8);
      background: rgba(0, 0, 0, 0.8); }
  .grid-table .old-log .cell-head {
    opacity: 0.4; }
  .grid-table .cell-content, .grid-table.waku2 .book-meta, .grid-table.waku2 .book-image, .grid-table.waku2 .book-memo,
  .grid-table .cell-body {
    transform: translate(0, -50%);
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    text-align: inherit;
    padding-left: 3px;
    padding-right: 3px; }
  .grid-table .cell-body {
    padding-top: 17px; }
  .grid-table .subject {
    font-size: 16px; }
    .grid-table .subject:empty {
      content: " "; }
  .grid-table .box {
    box-shadow: 0 0 2px #ffffff;
    border-radius: 3px;
    display: inline-block;
    position: relative;
    background: #fff;
    border: 2px solid #cccccc;
    width: 110px;
    height: 50px;
    line-height: 1;
    padding-top: 17px;
    vertical-align: center;
    text-align: center;
    font-size: 16px; }
    .grid-table .box.as-ghost {
      background: none;
      border: none; }
    .grid-table .box.with-title-before::before, .grid-table .box.with-title-after::after {
      content: attr(title);
      font-size: 9px;
      position: absolute; }
    .grid-table .box.with-title-before::before {
      top: 4px;
      left: 4px; }
    .grid-table .box.with-title-after::after {
      bottom: 4px;
      right: 4px; }
  .grid-table .cell-date {
    color: #444;
    font-size: 8px;
    text-align: center;
    position: absolute;
    left: 1px;
    right: 1px;
    bottom: 1px; }
  .grid-table .avoid-box {
    padding-right: 110px !important; }
  .grid-table .rireki-mark-area {
    margin-left: -3px;
    margin-right: -3px; }
    .grid-table .rireki-mark-area:empty {
      content: " "; }
  .grid-table .memo-button {
    display: inline-block;
    border-radius: 3px;
    transform: translate(0, -50%);
    position: absolute;
    top: 50%;
    right: 0;
    width: 40px;
    height: 50px;
    text-align: right;
    line-height: 50px;
    color: #AAA;
    overflow: hidden;
    cursor: pointer; }
    .grid-table .memo-button::before {
      display: block;
      margin-right: 12px; }
    .grid-table .memo-button.active {
      color: #d63366 !important; }
    .grid-table .memo-button:hover {
      color: #444;
      background: rgba(255, 255, 255, 0.5); }
  .grid-table .with-memo-button {
    padding-right: 25px; }
  .grid-table.waku2 tr:not(.section) {
    height: 80px; }
  .grid-table.waku2 td:not(.section) {
    height: 80px; }
  .grid-table.waku2 .cell-inner {
    padding: 0; }
  .grid-table.waku2 .book-meta {
    text-align: left;
    left: 78px;
    right: 30px;
    width: auto; }
  .grid-table.waku2 .book-image {
    width: 68px;
    left: 5px;
    padding: 0; }
    .grid-table.waku2 .book-image img {
      max-width: 68px;
      max-height: 68px; }
  .grid-table.waku2 .book-memo {
    padding-right: 73px;
    text-align: right; }
    .grid-table.waku2 .book-memo .memo-button {
      text-align: center; }
      .grid-table.waku2 .book-memo .memo-button::before {
        margin: 0; }
  .grid-table.waku2 .book-title {
    font-size: 16px;
    max-height: 2.2em;
    overflow: hidden; }
  .grid-table.waku2 .reading-status {
    margin-top: 10px; }
  .grid-table.waku2 .reading-progress {
    text-shadow: 0 0 2px #ffffff;
    position: absolute;
    left: 5px;
    right: 5px;
    top: 15px;
    font-size: 16px;
    text-align: center; }
  .grid-table.waku2 .current-page {
    font-size: 1.6em; }
  .grid-table.waku2 .reading-progress-bar {
    box-shadow: 0 0 2px #ffffff;
    border-radius: 3px;
    position: absolute;
    left: 5px;
    right: 5px;
    bottom: 10px;
    border: 1px solid #468847;
    text-align: center;
    height: 20px;
    background: #dff0d8; }
    .grid-table.waku2 .reading-progress-bar .value {
      position: absolute;
      left: 0;
      top: 0;
      height: 18px;
      background: #468847;
      z-index: 1; }
    .grid-table.waku2 .reading-progress-bar .subject {
      text-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
      font-size: 14px;
      color: #FFF;
      position: relative;
      z-index: 2;
      line-height: 20px; }
  .grid-table.waku2 .icon-bookgrade,
  .grid-table.waku2 .icon-favorite {
    text-shadow: 0 0 2px #ffffff;
    display: inline-block;
    overflow: hidden; }
    .grid-table.waku2 .icon-bookgrade::before,
    .grid-table.waku2 .icon-favorite::before {
      display: block; }
  .grid-table.waku2 .icon-bookgrade {
    color: #999900;
    width: 20px;
    height: 24px;
    line-height: 28px; }
    .grid-table.waku2 .icon-bookgrade::before {
      font-size: 16px; }
  .grid-table.waku2 .icon-favorite {
    color: #d63366;
    width: 30px;
    height: 30px;
    line-height: 30px; }
    .grid-table.waku2 .icon-favorite::before {
      font-size: 24px; }

/**
 * Color Schemas
 */
/**
 * Font styles
 */
/**
 *
 */
/**
 * Breakpoints
 */
/**
 * Dimensions
 */
/**
 * Color Schemas
 */
/**
 * Font styles
 */
/**
 *
 */
/**
 * Breakpoints
 */
/**
 * Dimensions
 */
.clndr-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 1px;
  background: #cccccc;
  table-layout: fixed; }
  .clndr-table .header-day {
    background: #239c74;
    color: #FFF;
    text-align: center; }
  .clndr-table .day {
    background-image: linear-gradient(top, #f9f9f9, #ececec);
    text-align: center;
    height: 54px;
    cursor: pointer; }
    .clndr-table .day:hover {
      color: #FFF;
      background: #fff; }
  .clndr-table .event {
    background: #b8ddc9; }
  .clndr-table .adjacent-month,
  .clndr-table .next-month {
    background: #fff; }

.clndr-controls {
  height: 44px;
  color: #FFF;
  background: #239c74;
  border: solid white;
  border-width: 1px 1px 0;
  position: relative;
  box-sizing: content-box; }
  .clndr-controls .month {
    text-align: center;
    height: 44px;
    line-height: 44px; }
  .clndr-controls .clndr-next-button, .clndr-controls .clndr-previous-button {
    height: 44px;
    line-height: 44px;
    position: absolute;
    cursor: pointer;
    padding: 0 1em; }
    .clndr-controls .clndr-next-button:hover, .clndr-controls .clndr-previous-button:hover {
      background: #17674d; }
  .clndr-controls .clndr-next-button {
    top: 0;
    right: 0; }
  .clndr-controls .clndr-previous-button {
    top: 0;
    left: 0; }

/**
 * Color Schemas
 */
/**
 * Font styles
 */
/**
 *
 */
/**
 * Breakpoints
 */
/**
 * Dimensions
 */
/**
 * Color Schemas
 */
/**
 * Font styles
 */
/**
 *
 */
/**
 * Breakpoints
 */
/**
 * Dimensions
 */
button.no-style {
  padding: 0;
  margin: 0;
  color: inherit;
  border-style: none;
  background: transparent; }

.current-order {
  color: yellow !important; }

.btn-group, .btn-toolbar {
  margin: 1em auto; }

.login-form {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  *zoom: 1;
  background: #fff;
  padding: 2em;
  width: 480px; }
  .login-form:after {
    content: "";
    display: table;
    clear: both; }
  .login-form label {
    text-align: left !important; }

.label-dark {
  background: #444444; }

.label-sm {
  font-size: 0.9em; }

#runenvsign {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  color: white;
  background: rgba(255, 0, 255, 0.5);
  padding: 0 .5em; }

.label.grade-high {
  background-color: #b5dafd;
  border: 1px solid #52a8fa;
  color: #03427d; }

.label.grade-middle {
  background-color: #f8ffac;
  border: 1px solid #ccdf00;
  color: #6f7900; }

.label.grade-low {
  background-color: #ffcccc;
  border: 1px solid #ff6666;
  color: #990000; }

/**
 * 先生のスタンプマーク
 */
.mark-stamp {
  display: inline-block;
  width: 40px;
  height: 40px;
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle; }
  .mark-stamp.mark-stamp-xs {
    width: 16px !important;
    height: 16px !important; }
  .mark-stamp.mark-stamp-lg {
    width: 80px !important;
    height: 80px !important; }

/**
 * サムネールを列挙する
 */
.thumbnail-list {
  counter-reset: number;
  list-style: none;
  margin: 0;
  padding: 0; }
  .thumbnail-list li {
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
    display: inline-block;
    position: relative;
    margin-bottom: 1em;
    border: 5px solid #fff;
    background: #232323;
    min-height: 4em; }
  .thumbnail-list a {
    display: inline-block;
    color: #FFF;
    text-decoration: none;
    background: #FFF;
    min-height: 4em; }
  .thumbnail-list a:hover {
    opacity: .5; }
  .thumbnail-list .caption {
    margin: 0;
    padding: .25em; }
  .thumbnail-list img {
    width: 100%; }
  .thumbnail-list.size-lg {
    width: 300px; }
  .thumbnail-list.size-md {
    width: 200px; }
  .thumbnail-list.size-sm {
    width: 100px; }
  .thumbnail-list.with-numbering li::before {
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
    position: absolute;
    top: 0;
    left: 0;
    counter-increment: number;
    content: counter(number);
    display: block;
    width: 42px;
    height: 42px;
    line-height: 42px;
    text-align: center;
    color: #FFF;
    font-size: 1.5em;
    font-weight: bold;
    background: #444; }
  .thumbnail-list.with-numbering li.active::before {
    background: #239c74; }

/**
 * 両端の丸い、背景がテーマカラーのバナー
 */
.ovalhead, .challenge-start-panel .num-quizs, .challenge-score-board .score-count {
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  border-radius: 2em;
  background: #239c74;
  border: 5px solid #fff;
  color: #FFF;
  font-weight: normal;
  font-size: 1.2em;
  line-height: 1.2;
  margin: 1.5em auto;
  padding: .4em .5em;
  width: 280px;
  text-align: center; }
  .ovalhead .emph, .challenge-start-panel .num-quizs .emph, .challenge-score-board .score-count .emph {
    display: inline-block;
    position: relative;
    font-size: 1.4em;
    color: yellow; }

.rireki-mark {
  display: inline-block;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
  margin: 0;
  background: #b17794;
  color: #FFF;
  font-size: 12px;
  width: 14px;
  height: 14px;
  line-height: 16px;
  text-align: center; }
  .rireki-mark.x2 {
    transform: translate(-1px, -0.5px);
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25), 2px 2px 0 #b17794, 3px 3px 1px rgba(0, 0, 0, 0.25); }
  .rireki-mark.x3 {
    transform: translate(-2px, -1px);
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25), 2px 2px 0 #b17794, 3px 3px 1px rgba(0, 0, 0, 0.25), 4px 4px 0 #b17794, 5px 5px 1px rgba(0, 0, 0, 0.25); }
  .rireki-mark.mark-homework, .rireki-mark.mark-printer {
    box-shadow: none;
    text-shadow: none;
    position: relative;
    top: 1px;
    background: none;
    width: 16px;
    height: 16px;
    color: #444;
    overflow: hidden;
    margin: -1px; }
  .rireki-mark.mark-homework-none {
    display: none; }
  .rireki-mark.mark-finished, .rireki-mark.mark-homework-confirmed {
    box-shadow: none;
    width: 20px;
    height: 20px;
    background: url(images/c_stamp_done.png);
    background-size: 100% 100%;
    overflow: hidden;
    text-indent: -99em;
    position: relative;
    margin: -2px; }
  .rireki-mark.mark-confirmed {
    box-shadow: none;
    width: 20px;
    height: 20px;
    background: url(images/c_stamp_confirmed.png);
    background-size: 100% 100%;
    overflow: hidden;
    text-indent: -99em;
    position: relative;
    margin: -2px; }

/**
 * 罫線囲みのボタンアイテム
 */
.bbitem, .step-selector .btn, .homework-list .homework a {
  text-shadow: 0 0 2px #ffffff;
  border-radius: 4px;
  color: #444;
  position: relative;
  display: block;
  width: 100%;
  border: 3px solid #cccccc;
  background: #fff;
  margin: .5em auto;
  padding: .5em;
  text-align: left;
  font-size: 1.1em; }
  .bbitem:hover, .step-selector .btn:hover, .homework-list .homework a:hover {
    opacity: .5;
    color: #444;
    text-decoration: none; }
  .bbitem:active, .step-selector .btn:active, .homework-list .homework a:active {
    opacity: 1;
    background-color: #b8ddc9;
    text-decoration: none; }

/**
 * 段階的な選択の
 */
.step-selector {
  *zoom: 1;
  max-width: 940px;
  margin: 1.5em 0; }
  .step-selector:after {
    content: "";
    display: table;
    clear: both; }
  .step-selector .step {
    *zoom: 1;
    list-style: none;
    float: left;
    margin: 0 2% 0 0;
    padding: 0;
    width: 32%;
    overflow: visible; }
    .step-selector .step:after {
      content: "";
      display: table;
      clear: both; }
    .step-selector .step:last-child {
      margin: 0; }
  .step-selector li {
    margin: 0 0 .5em;
    overflow: visible; }
  .step-selector li.active .btn {
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
    border-color: #fff;
    background: #b8ddc9;
    font-weight: bold; }
  .step-selector.align-vertical {
    width: 100%; }
    .step-selector.align-vertical .step {
      float: none;
      width: 100%; }
      .step-selector.align-vertical .step li {
        float: left;
        margin: 0 2% 0 0; }
      .step-selector.align-vertical .step .btn {
        padding-left: 1em;
        padding-right: 1em;
        min-width: 120px;
        text-align: center; }
  .step-selector.with-next-arrow li.active .btn::after {
    text-shadow: 0 0 2px #ffffff;
    transform: scale(2, 2) translate(0, -25%);
    position: absolute;
    right: -3%;
    top: 50%;
    color: yellow;
    z-index: 10000; }
  .step-selector.with-next-arrow.align-vertical li.active .btn::after {
    transform: scale(2, 2) translate(0, -25%);
    left: 50%;
    bottom: 10px; }
  .step-selector.with-step-num-title .step::before {
    counter-increment: stepnum;
    content: counter(stepnum) ". " attr(title) "をえらぶ";
    display: block;
    margin: 1em 0 .25em;
    color: #1a7255;
    font-size: 1.1em;
    font-weight: bold; }
  .step-selector.with-step-num-title.start-num {
    counter-reset: stepnum; }

#browsehappy {
  position: relative;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  margin: 0;
  background: #ff205d;
  border: 2px solid #b90033;
  color: white;
  text-align: center;
  font-size: 1em;
  padding: .5em; }

/**
 * Color Schemas
 */
/**
 * Font styles
 */
/**
 *
 */
/**
 * Breakpoints
 */
/**
 * Dimensions
 */
/**
 * Color Schemas
 */
/**
 * Font styles
 */
/**
 *
 */
/**
 * Breakpoints
 */
/**
 * Dimensions
 */
#entry-instruction {
  border-radius: 4px;
  position: relative;
  border: 10px solid rgba(66, 139, 202, 0.5);
  width: 720px;
  background: #fff;
  padding: 20px; }
  #entry-instruction p {
    line-height: 1.25em; }
  #entry-instruction .title {
    margin: 0 0 20px; }
  #entry-instruction .text-xl {
    font-size: 2em; }

#entry-card-panel {
  position: relative;
  height: 360px; }

.entry-card {
  border-radius: 4px;
  position: absolute;
  border: 10px solid rgba(66, 139, 202, 0.5);
  width: 720px;
  height: 360px;
  top: 0;
  left: 0;
  background: #fff; }
  #entry-card-thumb-panel .entry-card {
    position: relative;
    border-width: 5px;
    width: 180px;
    height: 90px;
    left: 0 !important; }
    #entry-card-thumb-panel .entry-card:hover, #entry-card-thumb-panel .entry-card.active {
      border-color: rgba(66, 139, 202, 0.5); }
  .entry-card header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 50px;
    color: #FFF;
    background: #239c74; }
    #entry-card-thumb-panel .entry-card header {
      height: 12.5px; }
  .entry-card footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 20px;
    height: 68px;
    line-height: 68px;
    background: #eee; }
    #entry-card-thumb-panel .entry-card footer {
      display: none; }
  .entry-card .body {
    position: absolute;
    top: 50px;
    bottom: 50px;
    left: 0;
    right: 0;
    padding: 20px;
    overflow: scroll; }
    .entry-card .body.no-figure {
      color: #777;
      background: #eee;
      line-height: 240px;
      text-align: center; }
    #entry-card-thumb-panel .entry-card .body {
      top: 12.5px;
      bottom: 0;
      padding: 5px;
      overflow: hidden; }
  .entry-card .title {
    margin: 0;
    font-size: 1.5em;
    line-height: 50px;
    padding: 0 20px; }
    #entry-card-thumb-panel .entry-card .title {
      font-size: 0.375em;
      line-height: 12.5px;
      padding: 0 5px; }
  .entry-card .image {
    max-width: 100%;
    width: 100%;
    margin-bottom: 40px; }
    .entry-card .image:last-child {
      margin-bottom: 0; }
  .entry-card input[type="text"] {
    text-align: center;
    font-size: 2em;
    height: 2em;
    width: 200px;
    margin: auto; }
  .entry-card input[type="text"]::after {
    content: attr(title);
    display: block;
    width: 100px;
    height: 100px; }
  .entry-card button {
    height: 48px; }
  .entry-card .score-wrapper {
    margin: 24px auto;
    text-align: center; }
  .entry-card .score-wrapper-inner {
    display: inline-block;
    position: relative; }
  .entry-card .score-suffix {
    transform: translate(100%, 0);
    position: absolute;
    right: -10px;
    bottom: 0; }
  .entry-card.grade-high::before, .entry-card.grade-low::before {
    text-shadow: 0 0 .1px white;
    transform: translate(-50%, -50%);
    line-height: 1;
    margin: 0;
    z-index: 101;
    position: absolute;
    left: 50%;
    top: 50%;
    font-size: 20em; }
    #entry-card-thumb-panel .entry-card.grade-high::before, #entry-card-thumb-panel .entry-card.grade-low::before {
      font-size: 70px;
      transform: translate(-50%, -40%); }
  .entry-card.grade-high {
    color: #00a29a; }
  .entry-card.grade-low {
    color: #f8b62c; }
  .entry-card.no-image::after {
    transform: translate(-50%, -50%);
    border-radius: 2px;
    display: block;
    line-height: 1;
    margin: 0;
    z-index: 100;
    position: absolute;
    padding: .5em;
    left: 50%;
    top: 50%;
    background: #cccccc;
    color: #FFF;
    font-size: 2em;
    content: "NO IMAGE"; }
    #entry-card-thumb-panel .entry-card.no-image::after {
      font-size: 8px; }

#entry-card-thumb-panel {
  *zoom: 1;
  clear: both;
  margin: 2em 0;
  min-height: 320px; }
  #entry-card-thumb-panel:after {
    content: "";
    display: table;
    clear: both; }
  #entry-card-thumb-panel .entry-card {
    float: left;
    margin: 0 10px 10px 0;
    border-color: #cccccc;
    cursor: pointer; }

.grid-table.can-not-update-seal .kenin-cell .cell-button {
  background-image: none !important;
  cursor: default !important; }
  .grid-table.can-not-update-seal .kenin-cell .cell-button:hover {
    background-image: none !important; }

.grid-table.on-view .row-header .cell-button {
  background-image: none !important;
  cursor: default !important; }
  .grid-table.on-view .row-header .cell-button:hover {
    background-image: none !important; }

.grid-table.on-entry.can-not-evaluate-mondai .normal-cell .cell-button,
.grid-table.on-entry.can-not-evaluate-mondai .row-header .cell-button {
  background-image: none !important;
  cursor: default !important; }
  .grid-table.on-entry.can-not-evaluate-mondai .normal-cell .cell-button:hover,
  .grid-table.on-entry.can-not-evaluate-mondai .row-header .cell-button:hover {
    background-image: none !important; }

#test-list-table .cell-inner-view {
  display: block; }

#test-list-table .cell-inner-input {
  display: none; }

#test-list-table.on-entry .cell-inner-view {
  display: none; }

#test-list-table.on-entry .cell-inner-input {
  display: block; }

#test-list-table.can-not-update-test-score.on-entry .cell-inner-input {
  display: none; }

.label-reading,
.label-shadowing {
  background: white;
  color: white;
  text-shadow: none; }

.label-reading {
  background: #ff829f; }

.label-shadowing {
  background: #0098ff; }

/**
 * Color Schemas
 */
/**
 * Font styles
 */
/**
 *
 */
/**
 * Breakpoints
 */
/**
 * Dimensions
 */
/**
 * Color Schemas
 */
/**
 * Font styles
 */
/**
 *
 */
/**
 * Breakpoints
 */
/**
 * Dimensions
 */
/**
 * チャレンジの開始ページのスタイル
 */
.challenge-start-panel-background {
  overflow: hidden;
  margin-top: -1.5em;
  padding-top: 1.5em; }

.challenge-start-panel {
  position: relative;
  height: 480px; }
  .challenge-start-panel::before {
    content: url("images/q_background.svg");
    position: absolute;
    width: 1720px;
    top: -1.5em;
    left: -660px;
    height: 600px;
    z-index: -1; }
  .challenge-start-panel .title {
    display: block;
    overflow: hidden;
    line-height: 2;
    margin: 20px 0 0;
    padding: 0;
    width: 420px;
    height: 64px;
    font-size: 2em; }
    .challenge-start-panel .title::before {
      content: url("images/q_title_challenge.svg"); }
    .challenge-start-panel .title::before {
      width: 100%;
      height: 100%;
      margin: 0; }
  .challenge-start-panel .num-quizs {
    font-size: 1.75em;
    margin: 40px 90px;
    width: 220px;
    padding: .2em 0; }
  .challenge-start-panel .start-button {
    margin: 0 80px; }
  .challenge-start-panel .instractions {
    margin: 2em 30px;
    padding-left: 1em; }

/**
 * チャレンジダイアログのスタイル
 */
.challenge-dialog .dialog-backdrop {
  background-color: rgba(255, 255, 255, 0.8); }

.challenge-dialog .dialog-window {
  border-radius: 20px;
  border: none;
  max-width: 1028px;
  max-height: 800px; }

.challenge-dialog .dialog-header {
  background-color: #239c74;
  padding: .5em 2em;
  overflow: hidden; }

.challenge-dialog .question-no {
  float: left;
  font-size: 2em;
  margin: -.5em 0 -1em;
  padding: .5em 1em;
  color: #FFF;
  background-color: #1a7255;
  line-height: 1.25; }

.challenge-dialog .remaining-questions {
  border-radius: 20px;
  white-space: nowrap;
  width: 7em;
  text-align: center;
  float: right;
  font-size: 1.25em;
  color: #239c74;
  background-color: #fff;
  padding: .2em 0;
  font-weight: bold; }

/**
 * クイズのスタイル
 */
.quiz {
  margin: 0;
  padding: 1% 5% 0;
  font-size: 1.25em;
  background: #fff;
  position: relative;
  height: 100%; }
  .quiz img {
    max-width: 100%; }
  .quiz .figure {
    text-align: center; }
  .quiz .mondai {
    *zoom: 1;
    overflow: auto; }
    .quiz .mondai:after {
      content: "";
      display: table;
      clear: both; }
  .quiz .choices {
    *zoom: 1;
    overflow: auto;
    clear: both;
    list-style: none;
    margin: 0;
    padding: 1em 0 0; }
    .quiz .choices:after {
      content: "";
      display: table;
      clear: both; }
    .quiz .choices.answerd .choice {
      cursor: default; }
    .quiz .choices.answerd .choice:hover {
      opacity: 1; }
    .quiz .choices .choice {
      *zoom: 1;
      border-radius: 10px;
      position: relative;
      border: 5px solid #cccccc;
      background: #fff;
      padding: .5em 1em;
      cursor: pointer; }
      .quiz .choices .choice:after {
        content: "";
        display: table;
        clear: both; }
      .quiz .choices .choice:hover {
        opacity: .5; }
      .quiz .choices .choice.choiced {
        border-color: #239c74;
        background-color: #f4fdfa; }
      .quiz .choices .choice.correct::before {
        content: url("images/q_mark_correct.svg");
        position: absolute;
        width: 80px;
        height: 80px;
        left: 1em;
        top: -1em; }
      .quiz .choices .choice.incorrect::before {
        content: url("images/q_mark_incorrect.svg");
        position: absolute;
        width: 80px;
        height: 80px;
        left: 1em;
        top: -1em; }
    .quiz .choices.choiced .choice:hover {
      opacity: 1; }
  .quiz.layout-a .mondai, .quiz.layout-b .mondai {
    height: 60%; }
  .quiz.layout-a .choices, .quiz.layout-b .choices {
    height: 40%; }
    .quiz.layout-a .choices .choice, .quiz.layout-b .choices .choice {
      float: left;
      width: 32%;
      margin-right: 2%;
      min-height: 5em; }
    .quiz.layout-a .choices .choice:last-child, .quiz.layout-b .choices .choice:last-child {
      margin-right: 0; }
    .quiz.layout-a .choices .figure, .quiz.layout-b .choices .figure {
      float: right;
      margin: 0 0 .5em 1em; }
  .quiz.layout-c .mondai, .quiz.layout-d .mondai {
    height: 60%; }
  .quiz.layout-c .choices, .quiz.layout-d .choices {
    height: 40%; }
    .quiz.layout-c .choices .choice, .quiz.layout-d .choices .choice {
      min-height: 4em;
      margin-bottom: .5em; }
    .quiz.layout-c .choices .choice:last-child, .quiz.layout-d .choices .choice:last-child {
      margin-bottom: 0; }
    .quiz.layout-c .choices .figure, .quiz.layout-d .choices .figure {
      float: right;
      width: 32%;
      margin-left: 2%; }
  .quiz.layout-a .mondai .text, .quiz.layout-c .mondai .text {
    float: left;
    width: 66%; }
  .quiz.layout-a .mondai .figure, .quiz.layout-c .mondai .figure {
    float: right;
    width: 32%; }
  .quiz.layout-b .mondai .text, .quiz.layout-d .mondai .text {
    margin: 1em auto; }
  .quiz.layout-b .mondai .figure, .quiz.layout-d .mondai .figure {
    margin: 1em auto; }
  .quiz.layout-b .mondai > *:first-child, .quiz.layout-d .mondai > *:first-child {
    margin-top: 0; }
  .quiz .without-text .figure {
    margin: 0 !important;
    float: none !important;
    width: 100% !important; }

/**
 * 正解・不正解のバナーのスタイル
 */
.q-banner-correct, .q-banner-incorrect {
  transform: translate(-50%, -50%);
  position: fixed;
  top: 45%;
  left: 50%;
  z-index: 1000;
  width: 320px;
  height: 120px; }

.q-banner-correct {
  display: block;
  overflow: hidden;
  line-height: 2; }
  .q-banner-correct::before {
    content: url("images/q_banner_correct.svg"); }

.q-banner-incorrect {
  display: block;
  overflow: hidden;
  line-height: 2; }
  .q-banner-incorrect::before {
    content: url("images/q_banner_incorrect.svg"); }

/**
 * クイズのコメント表示のスタイル
 */
.quiz-comment .figure {
  margin-left: 1em;
  float: right; }

.quiz-comment .without-text .figure {
  float: none !important; }

/**
 * 結果表示画面のスタイル
 */
.challenge-score-board {
  background: url("images/q_bg_smile.svg") repeat center top;
  background-size: 50px 50px;
  text-align: center; }
  .challenge-score-board.perfectly {
    background-image: url("images/q_bg_laugh.svg"); }
  .challenge-score-board .inner {
    width: 100%; }
  .challenge-score-board .score-title {
    display: block;
    overflow: hidden;
    line-height: 2;
    width: 300px;
    height: 154px;
    margin: 0 auto; }
    .challenge-score-board .score-title::before {
      content: url("images/q_title_scorepage.svg"); }
  .challenge-score-board .point-name {
    display: inline-block;
    border-radius: 4px;
    background: #1a7255;
    font-size: 1.5em;
    margin: 1em auto 0;
    padding: .25em 1em;
    color: #FFF;
    width: auto; }
  .challenge-score-board .score-count {
    display: inline-block;
    font-size: 1.5em;
    font-weight: bold;
    margin-top: 1.5em; }
  .challenge-score-board .score-percent {
    display: inline-block;
    margin: .25em auto 0;
    font-size: 2em;
    font-weight: bold;
    color: #1a7255; }
  .challenge-score-board .comment-for-score {
    display: inline-block;
    border-radius: 2em;
    background: #fff;
    padding: 1em;
    margin: 1.25em auto 0;
    font-size: 1.2em;
    border: 5px solid #cccccc; }
    .challenge-score-board .comment-for-score p {
      margin: 0; }

/**
 * Color Schemas
 */
/**
 * Font styles
 */
/**
 *
 */
/**
 * Breakpoints
 */
/**
 * Dimensions
 */
/**
 * Color Schemas
 */
/**
 * Font styles
 */
/**
 *
 */
/**
 * Breakpoints
 */
/**
 * Dimensions
 */
/**
 * 宿題一覧
 */
.homework-list .dialog-body {
  background: #f6f9f4;
  padding-bottom: 4em; }
  .homework-list .dialog-body > *:first-child {
    margin-top: 0; }

.homework-list .deadline {
  margin-left: auto;
  margin-right: auto; }

.homework-list .subject {
  margin: 1em -14px;
  padding: .25em 1em;
  border-bottom: 2px dotted #cccccc;
  font-size: 1.25em;
  font-weight: bold; }
  .homework-list .subject::before {
    margin-right: .5em; }

.homework-list .homework a {
  padding-top: 0.25em;
  padding-bottom: 0.25em; }

/**
 * at Home 用のマーキングの追加
 */
.step-selector .btn {
  white-space: normal; }

.step-selector .btn::before {
  position: absolute;
  top: 50%;
  right: .5em; }

.step-selector .step {
  width: 23%;
  margin-right: 2%;
  max-width: 240px; }

.step-selector li.has-homework .btn {
  padding-right: 2.5em; }
  .step-selector li.has-homework .btn::before {
    transform: scale(2, 2) translate(0, -27%);
    color: #428bca; }

.step-selector li.completed .btn {
  padding-right: 2.5em; }
  .step-selector li.completed .btn::before {
    transform: scale(2, 2) translate(0, -27%);
    color: #ffd276; }

/**
 * at Home 用のマーキングの追加
 */
.navtab.for-the-points .lsf-homework, .navtab.for-the-points .step-selector li.has-homework .btn::before, .step-selector li.has-homework .navtab.for-the-points .btn::before {
  float: right;
  color: #428bca;
  font-size: 1.5em;
  margin: -.25em -.25em 0 .5em; }

.navtab.for-the-points li.active .lsf-homework, .navtab.for-the-points li.active .step-selector li.has-homework .btn::before, .step-selector li.has-homework .navtab.for-the-points li.active .btn::before {
  color: #fff; }

/**
 * 要点解説のページ
 */
.mondai-paper {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  background: white;
  padding: 1em;
  min-height: 480px; }

.start-button {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  margin: 0 auto;
  font-size: 2em;
  min-width: 240px; }

/**
 * Color Schemas
 */
/**
 * Font styles
 */
/**
 *
 */
/**
 * Breakpoints
 */
/**
 * Dimensions
 */
/**
 * Color Schemas
 */
/**
 * Font styles
 */
/**
 *
 */
/**
 * Breakpoints
 */
/**
 * Dimensions
 */
.video-player {
  *zoom: 1;
  background-color: #232323;
  max-width: 800px; }
  .video-player:after {
    content: "";
    display: table;
    clear: both; }
  .video-player video {
    margin: 0;
    padding: 0;
    width: 100%;
    height: auto;
    background-color: #000; }

.video-player-header {
  *zoom: 1;
  padding: .5em; }
  .video-player-header:after {
    content: "";
    display: table;
    clear: both; }
  .video-player-header h1 {
    margin: 0;
    padding: 0;
    color: #fff;
    font-size: 1.2em;
    line-height: 1.4em; }

.video-player-footer {
  *zoom: 1;
  margin: 0;
  padding: 0 .5em .5em; }
  .video-player-footer:after {
    content: "";
    display: table;
    clear: both; }
  .video-player-footer .pull-left {
    width: 20%; }
  .video-player-footer .pull-right {
    margin-top: 6px;
    width: 75%;
    padding-right: 120px; }
  .video-player-footer .progress {
    position: relative;
    float: left;
    width: 100%;
    margin: 0;
    overflow: visible; }
  .video-player-footer .thumb {
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    transform: scale(1.2, 1.2);
    position: absolute;
    display: block;
    margin-left: -10px;
    width: 20px;
    height: 100%;
    border: 1px solid #cccccc;
    overflow: hidden;
    text-indent: -99em; }
  .video-player-footer .time {
    float: right;
    margin-right: -120px;
    display: inline-block;
    color: #FFF; }

/**
 * Color Schemas
 */
/**
 * Font styles
 */
/**
 *
 */
/**
 * Breakpoints
 */
/**
 * Dimensions
 */
/**
 * Color Schemas
 */
/**
 * Font styles
 */
/**
 *
 */
/**
 * Breakpoints
 */
/**
 * Dimensions
 */
.hpanel .listening-player .listening-media-content {
  position: fixed;
  max-width: 800px;
  left: 230px;
  right: 20px;
  bottom: 50px; }

/**
 * リスニング系
 */
.listening-player {
  position: relative; }
  .listening-player .start-button {
    position: absolute;
    left: 50%;
    top: 150px;
    transform: translateX(-50%); }
  .listening-player .listening-media-content .media-audio {
    width: 100%; }
  .listening-player .listening-visual-content.for-text-content {
    padding: 20px; }
  .listening-player .listening-visual-content.for-image-content {
    padding: 0;
    min-height: 0; }
  .listening-player .listening-visual-content strong {
    font-weight: bold; }
  .listening-player .listening-visual-content p {
    width: 760px; }
  .listening-player .listening-visual-content p:nth-of-type(2) {
    color: blue; }
  .listening-player .listening-visual-content.content_layout_tango_listening p {
    position: absolute; }
  .listening-player .listening-visual-content.content_layout_tango_listening p:nth-of-type(1) {
    text-align: center;
    font-size: 4em;
    top: 45%; }
  .listening-player .listening-visual-content.content_layout_tango_listening p:nth-of-type(2) {
    text-align: center;
    top: 65%;
    font-size: 2em; }
  .listening-player .listening-visual-content.content_layout_ichimonittou p {
    position: absolute; }
  .listening-player .listening-visual-content.content_layout_ichimonittou p:nth-of-type(1) {
    font-size: 2em; }
  .listening-player .listening-visual-content.content_layout_ichimonittou p:nth-of-type(2) {
    text-align: center;
    top: 45%;
    font-size: 3em; }
  .listening-player .listening-visual-content.content_layout_reibun_listening p {
    position: relative; }
  .listening-player .listening-visual-content.content_layout_reibun_listening p:nth-of-type(1) {
    font-size: 3em; }
  .listening-player .listening-visual-content.content_layout_reibun_listening p:nth-of-type(2) {
    font-size: 1.5em; }
